import React,{useEffect} from "react";
import MyButton from "../components/button";
import Layout from "../components/layout";
import { navigate } from "gatsby";
import background from "../assets/images/backpat.png";
import Card from "../components/card";
import doctor from "../assets/images/doctor.png";

const IndexPage = () => {
 
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'PageView');
      }
    }
  },[])

  return (
    <Layout pageTitle="فيرتكس">
      <div style={styles.body}>
        <Card>
        <img
              src={doctor}
              alt="doctor"
              width="220"
            />
            <br />
          <h2 style={styles.subTitle}>أهلا بكم في</h2>
          <h1 style={styles.title}>المركز التخصصي لعلاج القولون</h1>
          <span style={styles.description}>
            أجب عن الاسئلة لنقوم بتشخیص حالتك بشکل دقیق من اجل اعطاء العلاج
            المناسب لك
          </span>
          <MyButton onClick={() => navigate("/questions")}>إبدأ الان </MyButton>
        </Card>
      </div>
    </Layout>
  );
};

const styles = {
  body: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    WebkitBackdropFilter: "blur(10px)",
    backgroundSize: "cover",
    margin: "auto",
    fontFamily: "bahij",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 20px",
    textAlign: "center",
  },
  title: {
    marginTop: "2px",
    fontSize: "24px",
  },
  subTitle: {
    marginBottom: "0px",
    fontWeight: "normal",
    fontSize: "20px",
  },
  description: {
    marginBottom: "30px",
  },
};

export default IndexPage;
